/* ###### 8.1 Dashboard One  ###### */

.az-content-dashboard {
  padding-top: 20px;
  @include media-breakpoint-up(lg) { padding-top: 30px; }
}

.az-dashboard-one-title {
  margin-bottom: 20px;

  @include media-breakpoint-up(sm) {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  @include media-breakpoint-up(lg) { margin-bottom: 30px; }
}

.az-dashboard-title {
  font-size: 22px;
  font-weight: 700;
  color: $gray-900;
  letter-spacing: -1px;
  margin-bottom: 3px;
}

.az-dashboard-text {
  font-size: 13px;
  margin-bottom: 0;
}

.az-dashboard-date {
  display: flex;
  align-items: center;
  margin-top: 20px;

  @include media-breakpoint-up(sm) { margin-top: 0; }

  .date {
    display: flex;

    > div:first-child {
      font-size: 32px;
      font-family: Arial, sans-serif;
      font-weight: 500;
      color: $gray-900;
      line-height: .9;

      @include media-breakpoint-up(md) { font-size: 36px; }
    }

    > div:last-child {
      margin-left: 5px;

      span {
        display: block;
        color: $gray-900;
        font-weight: 700;
        font-size: 12px;
        line-height: 1.2;
        letter-spacing: -.5px;

        @include media-breakpoint-up(md) { font-size: 13px; }

        &:last-child {
          color: $gray-600;
          font-weight: 400;
        }
      }
    }
  }

  > i {
    font-size: 21px;
    line-height: 0;
    display: block;
    margin: 0 15px;
    color: $gray-500;

    @include media-breakpoint-up(md) {
      margin: 0 20px;
      font-size: 24px;
    }
  }
}

.az-dashboard-nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid $gray-300;
  margin-bottom: 20px;

  .nav-link {
    font-size: 12px;
    padding: 5px 10px 8px;
    display: flex;
    align-items: center;
    line-height: 1;

    @include media-breakpoint-up(sm) { font-size: $font-size-base; }
    @include media-breakpoint-up(lg) { padding: 5px 15px 10px; }

    @include hover-focus() { color: $az-color-primary; }

    + .nav-link { border-left: 1px solid $gray-300; }
  }

  .nav:first-child {
    .nav-link {
      color: $gray-900;
      display: none;

      @include media-breakpoint-up(sm) { display: block; }

      @include hover-focus() { color: $az-color-primary; }

      &.active { color: $az-color-primary; }

      &:first-child { padding-left: 0; }

      &:first-child,
      &:last-child {
        display: block;
      }
    }
  }

  .nav:last-child {
    .nav-link {
      color: $gray-700;
      display: none;

      @include media-breakpoint-up(md) { display: block; }

      @include hover-focus() { color: $az-color-primary; }

      i {
        font-size: 16px;
        margin-right: 7px;
        line-height: 0;
      }

      &:last-child {
        padding-right: 0;
        display: block;

        i { margin-right: 0; }

        @include media-breakpoint-up(md) { display: none; }
      }
    }
  }
}

.card-dashboard-one {
  position: relative;
  border-color: $gray-300;

  .card-header {
    padding: 20px;
    background-color: transparent;

    @include media-breakpoint-up(sm) {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
    }

    .card-title {
      color: $gray-900;
      font-weight: 700;
      font-size: 14px;
      line-height: 1;
      margin-bottom: 3px;
    }

    .card-text {
      margin-bottom: 0;
    }

    .btn-group {
      margin-top: 15px;
      @include media-breakpoint-up(sm) { margin-top: 0; }

      .btn {
        font-size: 12px;
        font-weight: 500;
        padding: 5px 10px;
        min-height: inherit;
        background-color: #fff;
        color: $gray-600;
        border: 1px solid $gray-300;

        @include hover-focus() {
          background-color: $gray-100;
          border-color: $gray-300;
        }

        + .btn { margin-left: -1px; }

        &.active {
          color: $gray-900;
          background-color: $gray-200;
          border-color: $gray-300;
        }
      }
    }
  }

  .card-body {
    padding: 10px 0 20px;
    position: relative;
    overflow: hidden;

    .flot-chart-wrapper {
      position: relative;
      margin-left: -28px;
      margin-right: -20px;
    }

    .flot-chart {
      width: 100%;
      height: 150px;

      @include media-breakpoint-up(sm) { height: 275px; }
      @include media-breakpoint-only(lg) { height: 252px; }

      .flot-y-axis > div {
        transform: translateX(50px);
        text-shadow: 1px 1px rgba(#fff, .75);
        color: $body-color;
        font-weight: 700;
        font-size: 11px;
      }

      .flot-x-axis > div {
        color: $gray-500;
        font-weight: 500;
        font-size: 11px;
      }
    }
  }

  .card-body-top {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    padding-left: 20px;

    @include media-breakpoint-up(sm) {
      position: absolute;
      top: -5px;
      left: 20px;
      z-index: 5;
      padding-left: 0;
    }

    > div {
      flex-basis: 50%;
      @include media-breakpoint-up(sm) { flex-basis: auto; }

      + div {
        @include media-breakpoint-up(sm) { margin-left: 30px; }
      }
    }

    label {
      font-size: 12px;
      margin-bottom: 3px;

      @include media-breakpoint-up(sm) { font-size: $font-size-base; }
    }

    h2 {
      font-size: 20px; //$font-size-base;
      font-weight: 600;
      font-family: 'Archivo', $font-family-base;
      color: $gray-900;

      @include media-breakpoint-up(sm) {
        font-size: 24px;
        letter-spacing: -.5px;
      }
    }
  }
}

.card-dashboard-two {
  height: 100%;
  border-radius: 0;
  border-color: $gray-300;
  background-color: rgba(#fff,.5);

  .card-header {
    background-color: transparent;
    padding: 20px 20px 0;

    h6 {
      font-size: 28px;
      font-weight: 600;
      font-family: 'Archivo', $font-family-base;
      color: $gray-900;
      display: flex;
      align-items: baseline;
      margin-bottom: 2px;
      line-height: 1;
      letter-spacing: -1px;

      @include media-breakpoint-only(lg) { font-size: 24px; }

      i {
        margin-left: 10px;
        font-size: 24px;
      }

      small {
        font-size: 12px;
        font-weight: 400;
        font-family: $font-family-base;
        margin-left: 5px;
        color: $gray-600;
        letter-spacing: normal;
      }
    }

    p {
      margin-bottom: 0;
      font-size: 13px;
    }
  }

  .card-body {
    padding: 0;
    overflow: hidden;
  }

  .chart-wrapper {
    position: relative;
    margin: 0 -10px -10px;
  }

  .flot-chart {
    width: 100%;
    height: 100px;
  }
}

.card-dashboard-three {
  height: 150px;
  border-radius: 0;
  border-color: $gray-300;
  background-color: rgba(#fff,.5);

  @include media-breakpoint-up(md) { height: 170px; }
  @include media-breakpoint-up(lg) { height: 100%; }

  .card-header {
    background-color: transparent;
    padding: 20px;
    position: absolute;
    max-width: 50%;

    h6 {
      font-size: 28px;
      font-weight: 600;
      font-family: 'Archivo', $font-family-base;
      color: $gray-900;
      display: flex;
      align-items: baseline;
      margin-bottom: 15px;
      line-height: 1;
      letter-spacing: -1px;

      @include media-breakpoint-up(xl) { font-size: 32px; }

      small {
        font-size: 12px;
        font-weight: 400;
        font-family: $font-family-base;
        margin-left: 5px;
        letter-spacing: normal;
      }
    }

    p {
      margin-bottom: 10px;
      font-weight: 700;
      text-transform: uppercase;
      color: $gray-900;
    }

    > small {
      font-size: 11px;
      display: none;
      color: $gray-600;

      @include media-breakpoint-up(md) { display: block; }
    }
  }

  .card-body {
    padding: 0 0 0 20px;
    display: flex;
    justify-content: flex-end;
  }

  .chart {
    width: 70%;
    height: 150px;

    @media (min-width: 375px) { width: auto; }

    @include media-breakpoint-up(md) { height: 170px; }
    @include media-breakpoint-up(lg) {
      height: 182px;
      width: 80%;
    }
  }
}

.card-dashboard-pageviews {
  border-color: $gray-300;
  border-radius: 0;
  padding: 20px;

  .card-header {
    background-color: transparent;
    padding: 0 0 10px;
  }

  .card-title {
    font-weight: 700;
    font-size: 14px;
    color: $gray-900;
    margin-bottom: 5px;
  }

  .card-text {
    font-size: 13px;
    margin-bottom: 0;
  }

  .card-body { padding: 0; }
}

.card-dashboard-four {
  border-radius: 0;
  border-color: $gray-300;
  padding: 20px;
  height: 100%;

  .card-header {
    padding: 0 0 20px;
    background-color: transparent;
  }

  .card-title {
    font-weight: 700;
    font-size: 14px;
    color: $gray-900;
    margin-bottom: 0;
  }

  .card-body {
    padding: 0;
    @include media-breakpoint-up(lg) { padding: 0 20px; }
  }

  .chart {
    width: calc(100vw - 80px);
    height: 200px;

    @media (min-width: 375px) { width: auto; }

    @include media-breakpoint-up(lg) { height: 85%; }
  }
}

.card-dashboard-five {
  border-color: $gray-300;
  padding: 20px;

  .card-header {
    padding: 0;
    background-color: transparent;
  }

  .card-title {
    font-weight: 700;
    font-size: 14px;
    color: $gray-900;
    margin-bottom: 5px;
  }

  .card-text {
    display: block;
    font-size: 12px;
  }

  .card-body {
    padding: 20px 0 5px;

    label {
      display: block;
      margin-bottom: 2px;
      font-size: 12px;
    }

    h4 {
      color: $gray-900;
      font-family: 'Archivo', $font-family-base;
      font-weight: 700;
      letter-spacing: -.5px;
      margin-bottom: 0;
    }
  }

  .card-chart {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;

    @include media-breakpoint-up(sm) {
      margin-bottom: 0;
      margin-right: 10px;
    }
  }
}

.card-table-one {
  padding: 20px 20px 10px;
  border-color: $gray-300;

  .card-title {
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 5px;
  }

  .table-responsive {
    width: calc(100vw - 80px);
    @include media-breakpoint-up(sm) { width: 100%; }
  }

  .table {
    margin-bottom: 0;

    thead,
    tbody {
      tr > th,
      tr > td {
        vertical-align: middle;
        white-space: nowrap;
        padding-right: 2px;

        @include media-breakpoint-up(md) {
          padding-left: 0;
          padding-right: 0;
        }

        &:nth-child(3),
        &:nth-child(4),
        &:nth-child(5) { text-align: right; }

        strong { color: $gray-900; }
      }
    }
  }

  .flag-icon {
    font-size: 20px;
    line-height: 1;
    @include border-radius(100%);
  }
}

.page-view-chart-wrapper {
  @include media-breakpoint-up(xl) { 
    height: 330px;
    margin-top: -56px;
  }
}

.sessions-chart-wrapper {
  @include media-breakpoint-up(xl) { 
    height: 180px;
    width: 80%;
  }
}

.acquisition-chart {
  canvas {
    @extend .w-50;
  }
}

.proBanner {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9999;
  .purchase-popup {
    background: #000;
    color: #fff;
    padding: 8px 20px;
    @include border-radius(3px);

    .btn {
      margin-right: 20px;
      font-weight: 500;
      color: $white;
      @include border-radius(5px);
      @include transition-duration(0.2s);

      &.download-button {
        background: rgba(249, 249, 249, 0.7);
        color: #969292;
        border: 1px solid darken(#e4e4e4,5%);
      }

      &.purchase-button {
        background-color: theme-color(info);
        color: $white;
        border: none;
        line-height: 1;
        vertical-align: middle;
      }
    }

    p {
      margin-bottom: auto;
      margin-top: auto;
      color: darken(#e4e4e4,20%);
      font-weight: 400;
      vertical-align: middle;
      line-height: 1;
    }

    i {
      vertical-align: middle;
      line-height: 1;
      margin: auto 0;
      color: darken(#e4e4e4,20%);
    }
    .bannerClose {
      cursor: pointer;
    }
  }
  &.hide {
    display: none;
  }
}